.App {
  text-align: center;
}

.App-logo {
  height: 40px;
  pointer-events: none;
  animation-play-state: var(--logo-animation-state);
  position: relative;
  right: 0;
}

.main {
  position: relative;
  top: 10px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation-name: App-logo-spin;
    animation-iteration-count: infinite;
    animation-duration: 60s;
    animation-timing-function: linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

